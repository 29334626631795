/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  width: 100%; /* Set a default minimum width */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 5000; /* Add a z-index if needed */
  bottom: 0px; /* 30px from the bottom */
}
#snackbar.success {
  background-color: green;
  font-size: 12px;
  color: #fff; /* White text color */
}
#snackbar.fail {
  background-color: #ff4646;
  font-size: 12px;
  color: #fff; /* White text color */
}
/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
}